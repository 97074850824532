.swal-title {
  font-size: 22px;
}
.swal-content h3 {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.65);
}
.swal-content code {
  background-color: #fefae3;
  padding: 17px;
  border: 1px solid #f0e1a1;
  display: block;
  margin: 22px;
  text-align: center;
  color: #61534e;
}
.swal-content a {
  color: #9c27b0;
}
.swal-content a:hover {
  color: #00897b;
}
.swal-button--danger {
  border-radius: 18px;
  background: #f44336;
}
.swal-button--confirm {
  border-radius: 18px;
  background: #4caf50;
}
.swal-button--confirm:hover {
  background: #3c8f40 !important;
}
.swal-button--login {
  border-radius: 18px;
  background: #4caf50;
}
.swal-button--login:hover {
  background: #3c8f40 !important;
}
.swal-button--reset {
  border-radius: 18px;
  background: #f44336;
}
.swal-button--reset:hover {
  background: #e65100 !important;
}
.swal-button--done {
  border-radius: 18px;
  background: linear-gradient(45deg, #9c27b0 30%, #d500f9 90%);
  color: #fafafa;
  box-shadow: '0 3px 5px 2px rgba(170, 0, 255, .30)';
  border-color: #9c27b0;
}
.swal-button--done:hover {
  background: linear-gradient(45deg, #7b1fa2 30%, #9c27b0 90%);
}
.swal-button--cancel {
  border-radius: 18px;
  border: solid 1px #9e9e9e;
  color: #424242;
  background-color: #fafafa;
}
.progress-icon {
  position: relative;
  padding-top: 8px;
  color: #a5dc86;
}
.save-icon {
  font-size: 34px !important;
  color: #00acc1;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -17px;
  margin-left: -17px;
}

.swal-footer {
  white-space: nowrap;
}
